import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Pwa } from '@freelancer/pwa';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';
import { ModalService } from '../modal';
import { UI_CONFIG } from '../ui.config';
import { UiConfig } from '../ui.interface';

@UntilDestroy({ className: 'UpdateNativeAlertComponent' })
@Component({
  selector: 'fl-update-native-alert',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateNativeAlertComponent implements OnInit {
  constructor(
    private pwa: Pwa,
    private modalService: ModalService,
    @Inject(UI_CONFIG) public uiConfig: UiConfig,
  ) {}

  async ngOnInit(): Promise<void> {
    if (await this.needsToUpdate()) {
      this.modalService.open('UpdateNativeModal', { closeable: false });
    }
  }

  /**
   * Whether to force the user to update their native app.
   *
   * Note: there are UI tests for this for both apps (native-update.spec)
   * you update any of this logic, update the tests
   */
  async needsToUpdate(): Promise<boolean> {
    if (this.pwa.isNative() && this.pwa.getPlatform() === 'android') {
      const appVersion = await firstValueFrom(
        this.pwa.appVersion().pipe(untilDestroyed(this)),
      ).catch(() => undefined);

      // If Capacitor plugins didn't load properly, app version may be undefined.
      // In that case, don't show the update modal because they could be on the latest version.
      if (!appVersion || !appVersion.nativeVersion) {
        return false;
      }

      // Freelancer and Loadshift apps have different version numbers.
      switch (this.uiConfig.theme) {
        case undefined:
          return !(await this.pwa.hasMinimumNativeAppVersion({
            major: 4,
            minor: 10,
          }));
        case 'freightlancer':
          // return !(await this.pwa.hasMinimumNativeAppVersion({
          //   major: 19,
          //   minor: 3,
          // }));
          return false;
        default:
          return false;
      }
    }

    return false;
  }
}
