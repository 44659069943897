import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import type { OnDestroy } from '@angular/core';
import {
  inject,
  Injectable,
  PLATFORM_ID,
  RendererFactory2,
} from '@angular/core';
import { LocalStorage } from '@freelancer/local-storage';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import {
  BehaviorSubject,
  firstValueFrom,
  fromEvent,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import type { Theme, ThemeSetting } from './theme.types';

@UntilDestroy({ className: 'ThemeService' })
@Injectable({
  providedIn: 'root',
})
export class ThemeService implements OnDestroy {
  private document = inject(DOCUMENT);
  private localStorage = inject(LocalStorage);
  private platformId = inject(PLATFORM_ID);
  private renderer = inject(RendererFactory2).createRenderer(null, null);

  private readonly DARK_MODE_MEDIA_QUERY = '(prefers-color-scheme: dark)';

  /**
   * themeSetting is the actual setting that the user has chosen (or been defaulted to).
   * This can be: 'light' | 'dark' | 'system'. This setting is stored in local storage.
   */
  private readonly themeSetting$: Observable<ThemeSetting>;
  /**
   * Theme is the actual theme being used in the app. This is either 'light' or 'dark'.
   * If the themeSetting is 'system', then the theme will be 'light' or 'dark' based on the user's OS settings.
   */
  private readonly theme$: Observable<Theme>;
  private subscription = new Subscription();

  /**
   * Used for ensuring that the dark mode is forced after the theme has been initially applied in fl-theme.
   */
  private _themeInitializedSubject$ = new ReplaySubject<boolean>(1);
  themeInitialized$ = this._themeInitializedSubject$.asObservable();
  private _removingForcedDarkMode$ = new BehaviorSubject<boolean>(false);
  removingForcedDarkMode$ = this._removingForcedDarkMode$.asObservable();

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      // The setting that the user has selected.
      this.themeSetting$ = this.localStorage.get('theme').pipe(
        map(theme => theme ?? 'light'),
        shareReplay({ bufferSize: 1, refCount: true }),
      );

      // Listen for changes to the system theme.
      this.subscription.add(
        fromEvent(
          window.matchMedia(this.DARK_MODE_MEDIA_QUERY),
          'change',
        ).subscribe(async event => {
          if (!('matches' in event)) {
            return;
          }

          const setting = await firstValueFrom(
            this.themeSetting$.pipe(untilDestroyed(this)),
          );

          if (setting === 'system') {
            this.applyTheme(event.matches ? 'dark' : 'light');
          }
        }),
      );

      // The actual theme being used in the app.
      // Derived from the themeSetting and the system theme.
      this.theme$ = this.themeSetting$.pipe(
        map(theme =>
          theme === 'system'
            ? window.matchMedia(this.DARK_MODE_MEDIA_QUERY).matches
              ? 'dark'
              : 'light'
            : theme,
        ),
      );
    } else {
      this.themeSetting$ = of('light');
      this.theme$ = of('light');
    }
  }

  getThemeSetting(): Observable<ThemeSetting> {
    return this.themeSetting$;
  }

  getTheme(): Observable<Theme> {
    return this.theme$;
  }

  async setThemeSetting(theme: ThemeSetting): Promise<void> {
    await this.localStorage.set('theme', theme);
  }

  applyTheme(theme: Theme): void {
    if (isPlatformBrowser(this.platformId)) {
      const docElement = this.document.documentElement;
      if (theme === 'dark') {
        this.renderer.addClass(docElement, 'dark');
      } else {
        this.renderer.removeClass(docElement, 'dark');
      }
    }
  }

  /**
   * Sets up the initial theme subject to be used by pages forcing dark mode.
   */

  initializeTheme(theme: Theme): void {
    this._themeInitializedSubject$.next(true);
    this.applyTheme(theme);
  }

  /**
   * These are used to store the current active theme so we can return to it after we no longer need to force dark mode.
   */
  private async setUserOriginalTheme(theme: Theme | null): Promise<void> {
    await this.localStorage.set('userOriginalTheme', theme);
  }

  private async getUserOriginalTheme(): Promise<Theme | undefined | null> {
    return firstValueFrom(
      this.localStorage.get('userOriginalTheme').pipe(untilDestroyed(this)),
    );
  }

  async forceDarkMode(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      const themeInitialized = await firstValueFrom(
        this.themeInitialized$.pipe(untilDestroyed(this)),
      );

      if (themeInitialized) {
        this.subscription.add(
          this.removingForcedDarkMode$
            .pipe(take(2)) // We take(2) to ensure that this only runs for each `removingForcedDarkMode` set-reset emission.
            .subscribe(async isRemovingForcedDarkMode => {
              if (!isRemovingForcedDarkMode) {
                const currentActiveTheme = await firstValueFrom(
                  this.getTheme().pipe(untilDestroyed(this)),
                );

                await this.setUserOriginalTheme(currentActiveTheme);

                this.applyTheme('dark');
              }
            }),
        );
      }
    }
  }

  async disableForceDarkMode(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      this._removingForcedDarkMode$.next(true);
      const currentThemeSetting = await firstValueFrom(
        this.getThemeSetting().pipe(untilDestroyed(this)),
      );

      const userOriginalTheme = await this.getUserOriginalTheme();

      if (
        currentThemeSetting === 'light' ||
        (currentThemeSetting === 'system' && userOriginalTheme === 'light')
      ) {
        this.applyTheme('light');
      }

      await this.setUserOriginalTheme(null);
      this._removingForcedDarkMode$.next(false);
    } else {
      this.applyTheme('light');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
